<template>
  <div class="smb-client-wrapper">
    <SmbTitles is-show-title is-show-sub-title title="Time-zone" />
    <div class="form-container">
      <div class="form-container__time-zone">
        <v-select
          v-model="timeZone"
          :items="timeZones"
          :rules="[rules.required]"
          item-text="name"
          item-value="id"
          validate-on-blur
          lazy-validation
          @change="changeTimezone"
        ></v-select>
      </div>
      <div class="form-container__title">
        Please note that it may take up to 24 hours for the update to effect
        existing scheduled content. <br />
        The change is immediate for any content you schedule after the change.
      </div>
    </div>
    <div class="footer-buttons">
      <SmbActionBtn
        class="arrow-button"
        isUseIcon
        iconName="mdi-arrow-left"
        width="20%"
        @click="$router.push({ name: 'profile-settings' })"
      />
      <SmbActionBtn
        width="80%"
        :isLoading="isSaveBtnLoading"
        :text="isTimezoneChanged ? 'Save' : 'Cancel'"
        :emitName="isTimezoneChanged ? 'save' : 'cancel'"
        @cancel="$router.push({ name: 'profile-settings' })"
        @save="saveHandler"
      />
    </div>
  </div>
</template>

<script>
import validationRules from "@/shared/validationRules";
import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn.vue";
import { mapActions, mapGetters } from "vuex";
import { SUPPORTED_TIME_ZONES } from "@/constants/globals";
export default {
  name: "Index",
  data: () => ({
    isSaveBtnLoading: false,
    isTimezoneChanged: false,
    timeZones: SUPPORTED_TIME_ZONES,
    timeZone: "",
  }),
  components: { SmbTitles, SmbActionBtn },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  mixins: [validationRules],
  created() {
    this.getCurrentSmbCustomer.time_zone.length
      ? (this.timeZone = this.getCurrentSmbCustomer.time_zone)
      : (this.timeZone = "");
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    async saveHandler() {
      try {
        this.isSaveBtnLoading = true;
        await this.updateCustomer({
          customerId: this.getCurrentSmbCustomer.id,
          params: {
            time_zone: this.timeZone,
          },
        });
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        this.isSaveBtnLoading = false;
        await this.$router.push({ name: "profile-settings" });
      } catch (e) {
        console.log(e);
      }
    },
    changeTimezone(val) {
      if (!val && !val.length) return;
      this.isTimezoneChanged = true;
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;
  .form-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 15px;
    max-width: 500px;
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #1f2325;
      margin-top: auto;
      margin-bottom: 20px;
      max-width: 500px;
    }
    &__time-zone {
      max-width: 500px;
      ::v-deep {
        .v-input__slot:after,
        .v-input__slot:before {
          display: none;
        }
        .v-input__slot {
          border: 1px solid #8f9192;
          border-radius: 5px;
          padding: 7px;
        }
      }
    }
  }
  .footer-buttons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    letter-spacing: 0;
    position: relative;
    @media screen and (min-width: 760px) {
      margin-top: 50px;
      max-width: 500px;
      padding: 0 20px 10px 20px;
      justify-content: flex-end;
    }

    .button-item {
      width: 100%;
      @media screen and (min-width: 760px) {
        width: 313px;
      }
    }
    .arrow-button {
      width: 20%;
      position: absolute;
      left: 0;
      z-index: 10;
      @media screen and (min-width: 760px) {
        display: none;
      }
    }
  }
}
</style>
